<template>
  <div>
    <page-header
      :background="require('@/assets/leagues/img.png')"
      title="Ligas"
    >
      <v-btn
        class="rounded-l-0 rounded-r-lg text-none"
        color="primary"
        to="/account/leagues"
      >
        <v-icon left>mdi-arrow-right</v-icon> Minhas Ligas
      </v-btn>
    </page-header>

    <v-container class="py-12">
      <div class="d-flex flex-column">
        <!-- filters -->
        <v-tabs
          v-model="tab"
          active-class="overtext--text"
          background-color="transparent"
          class="mb-6"
          slider-color="primary"
          show-arrows
        >
          <v-tab v-for="(item, i) in tabs" :key="i">
            {{ item.label }}
          </v-tab>
        </v-tabs>

        <GamesChips :disabled="loading" />

        <!-- loading -->
        <loader-content v-if="loading" class="my-12" />

        <!-- content -->
        <div v-else-if="_leagues.length > 0" class="my-12">
          <v-row justify="center" justify-md="start">
            <v-col
              v-for="(item, i) in _leagues"
              class="pb-12"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              :key="i"
            >
              <LeagueCard :league="item" />
            </v-col>
          </v-row>
        </div>

        <!-- empty -->
        <div v-else class="d-flex flex-column align-center pb-12">
          <span class="text-body-1 text-center subtext--text mb-4">
            Nenhum resultado foi encontrado
          </span>

          <v-btn
            v-if="tab !== 2"
            class="rounded-l-0 rounded-r-lg text-none"
            color="primary"
            @click="tab = 2"
          >
            <v-icon left>mdi-arrow-right</v-icon> Próximas ligas
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getLeagues } from "@/services/leagues";

import LeagueCard from "@/components/leagues/LeagueCard";
import GamesChips from "@/components/games/GamesChips";

export default {
  data() {
    return {
      loading: true,
      pagination: {
        page: 0,
        pageCount: 1,
        itemsPerPage: 20,
        total: 0,
      },
      leagues: [],
      tab: 0,
      tabs: [
        {
          label: "Inscrições abertas",
          value: 0,
        },
        {
          label: "Em andamento",
          value: 1,
        },
        {
          label: "Em Breve",
          value: 2,
        },
        {
          label: "Finalizadas",
          value: 3,
        },
      ],
    };
  },

  components: {
    GamesChips,
    LeagueCard,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    tab() {
      this.getData();
    },

    [`$route.query`](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    ...mapState(["modalities", "selected", "localUser"]),

    _leagues() {
      return this.leagues.filter((e) => !!e.isActive);
    },
  },

  methods: {
    ...mapActions(["setSelected"]),

    async getData(filter = {}) {
      try {
        const payload = {
          offset: 0,
          limit: 20,
          status: this.tab,
          ...filter,
        };

        await getLeagues(payload).then((res) => {
          this.leagues = res;
        });
      } catch (err) {
        this.leagues = [];
        // err
      } finally {
        this.loading = false;
      }
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 0;
      this.getData(value);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .v-slide-group__prev {
  min-width: 16px !important;
}

:deep() .v-slide-group__next {
  min-width: 16px !important;
}
</style>
